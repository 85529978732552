<!--  ******************************* -->
<!--  SECTION 1, auth FORMS (LOGIN, RECOVER PASS, SIGNUP) -->
<!--  ******************************* -->

<!--<div *ngIf="load" class="pt-5">
  <div align="center" class="load-setting">
    <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
  </div>
</div>-->

<div class="row" id="adjust-select">
  <div class="col-12 landS6"
    [ngStyle]="{'background-image': 'url(&quot;' + routeUploadFile+sectionHub?.background_image + '&quot;)'}">
    <!-- /. App Header -->
    <app-header class="app-header container" [id]="id" [change_password]="change_password"></app-header>
    <!-- WELCOME / LOGIN-->
    <div class="row s1">
      <div class="col-12 col-lg-2">
      </div>
      <div class="col-12 col-lg-8 s1-select">
        <!-- LOGIN FORM -->
        <div class="white">
          <h3 class="s-xl title-user-name mt-5"> Bienvenido <span class="pl-1">{{user?.first_name}} </span> </h3>
          <!-- <h4 class="s-xl title-user-name" style="color:#343a40;"> {{ nameAgency }} </h4> -->
          <p class="s-sm title-select-module"> Seleccione un módulo para continuar</p>
        </div>
      </div>
    </div>
    <div class="container py-5" id="set-icon-circle" >
      <div class="row">
        <div class="col-6 col-lg-3 g-3">
          <div class="text-center circle-icon" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_2}">
            <a [className]="!app.booking ? 'module-disabled ' : 'module-enabled'"
               [attr.href]="hasAccess('booking.acceso.habilitar') ? app.booking_url : null"
               (click)="hasAccess('booking.acceso.habilitar') ? navigateToApp(app.booking_url) : null"
               (mouseenter)="app.booking && (bookingHover=false)"
               (mouseleave)="app.booking && (bookingHover=true)"
               [style]="{cursor: app.booking ? 'pointer' : 'default'}"
            >
              <span [className]="!app.booking ? 'img-fluid mx-auto d-block' : 'img-fluid mx-auto d-block'">
                  <svg-icon src="../../../assets/img/select/icon_booking.svg"
                            id="set-content-circle"
                            style="top: 35px;"
                            [ngStyle]="{'fill': bookingHover ? 'white' : getClientsSettingAppAuth?.clients_setting_global?.color_main_3}"
                  >
                  </svg-icon>
                <p class="title-module" style="text-transform: uppercase; margin-top: 10px;"
                  [ngStyle]="{'color': bookingHover ? 'white' : getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">
                  {{module_names.booking}}</p>
              </span>
            </a>
          </div>
        </div>
        <div class="col-6 col-lg-3 g-3">
          <div class="text-center circle-icon" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_2}">
            <a [className]="!app.zoco ? 'module-disabled ' : 'module-enabled'"
               [attr.href]="hasAccess('zoco.acceso.habilitar') ? app.zoco_url : null"
               (click)="hasAccess('zoco.acceso.habilitar') ? navigateToApp(app.zoco_url) : null"
               (mouseenter)="app.zoco && (zocoHover=false)"
               (mouseleave)="app.zoco && (zocoHover=true)"
               [style]="{cursor: app.zoco ? 'pointer' : 'default'}">
              <span [className]="!app.zoco ? 'img-fluid mx-auto d-block' : 'img-fluid mx-auto d-block'">
                <!-- <ng-template [ngIf]="app.chat"> -->
                  <svg-icon src="../../../assets/img/select/ZOCO_ICONO_B.svg" id="set-content-circle"
                    [ngStyle]="{'fill': zocoHover ? 'white' : getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">
                  </svg-icon>
                <p class="title-module" style="text-transform: uppercase"
                  [ngStyle]="{'color': zocoHover ? 'white' : getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">
                  {{module_names.zoco}}</p>
              </span>
            </a>
          </div>
        </div>
        <div class="col-6 col-lg-3 g-3" id="circle-margin-query">
          <div class="text-center circle-icon" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_2}">
            <a [className]="!app.ABACO? 'module-disabled ' : 'module-enabled'"
               [attr.href]="hasAccess('abaco.acceso.habilitar') ? app.booking_abaco_url : null"
               (click)="hasAccess('abaco.acceso.habilitar') ? navigateToApp(app.abaco_url) : null"
               (mouseenter)="app.abaco && (abacoHover=false)"
               (mouseleave)="app.abaco && (abacoHover=true)"
               [style]="{cursor: app.abaco ? 'pointer' : 'default'}">
              <span [className]="!app.ABACO ? 'img-fluid mx-auto d-block' : 'img-fluid mx-auto d-block'">
                <!-- <ng-template [ngIf]="app.ABACO"> -->
                  <svg-icon src="../../../assets/img/select/icon_adm.svg" id="set-content-circle" style="top: 3.5em;"
                    [ngStyle]="{'fill': abacoHover ? 'white' : getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">
                  </svg-icon>
                <p class="title-module" style="text-transform: uppercase; margin-top: 3em;"
                  [ngStyle]="{'color': abacoHover ? 'white' : getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">
                  {{module_names.abaco}}</p>
              </span>
            </a>
          </div>
        </div>
        <div class="col-6 col-lg-3 g-3" id="circle-margin-query">
          <div class="text-center circle-icon" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_2}">
            <a [className]="!app.booking_backoffice ? 'module-disabled ' : 'module-enabled'"
               [attr.href]="hasAccess('backoffice.acceso.habilitar') ? app.booking_backoffice_url : null"
               (click)="hasAccess('backoffice.acceso.habilitar') ? navigateToApp(app.booking_backoffice_url) : null"
               (mouseenter)="app.booking_backoffice && (backofficeHover=false)"
               (mouseleave)="app.booking_backoffice && (backofficeHover=true)"
               [style]="{cursor: app.booking_backoffice ? 'pointer' : 'default'}">
              <span [className]="!app.booking_backoffice ? 'img-fluid mx-auto d-block' : 'img-fluid mx-auto d-block'">
                <!-- <ng-template [ngIf]="app.booking_backoffice"> -->
                  <svg-icon src="../../../assets/img/select/icon_auth.svg" id="set-content-circle"
                    [ngStyle]="{'fill': backofficeHover ? 'white' : getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">
                  </svg-icon>
                <p class="title-module" style="text-transform: uppercase; margin-top: 8px;"
                  [ngStyle]="{'color': backofficeHover ? 'white' : getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">
                  {{module_names.booking_backoffice}}</p>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="row" style="padding-top:170px;">
        <div class="col col-xg-5 col-lg-4 col-md-12 col-sm-12">
        </div>
        <div class="col col-xg-4 col-lg-4 col-md-12 col-sm-12 text-center">
          <button type="button" id="btn-logout" class="btn btn-lg rounded-pill"
            [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_3}"
            (click)="onLogout();"> LOG OUT </button>
        </div>
        <div class="col col-xg-5 col-lg-4 col-md-12 col-sm-12">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /. Footer component -->
<usb-footer></usb-footer>
